<template>
  <div id="policy-container">
    <div class="title">
      Branşlar
      <!-- Branşlar <span v-if="result.TotalCount != 0">({{ result.TotalCount }})</span> -->
    </div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Branşlar</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <el-button class="add" @click="setItem(null)"><i class="icon-plus"></i></el-button>
      </div>
    </div>

    <div class="all-data">
      <div class="list-table">
        <el-table :data="allSpecialities" style="width: 100%">
          <el-table-column label="Branş Adı">
            <template slot-scope="scope"> {{ scope.row.Title }} </template>
          </el-table-column>
            <el-table-column label="Branş Tipi">
            <template slot-scope="scope"> {{ scope.row.Type==true? "Hastane" :"Diğer" }} </template>
          </el-table-column>

          <el-table-column label="Durum" width="300" align="start">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.IsActive" @change="setActivity(scope.row)" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column width="300" align="center">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display: block" @click="setItem(scope.row.ID)">Düzenle</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided><span class="tex-danger" style="display: block" @click="deleteSpecialities(scope.row.ID)">Sil</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Branş bulunamadı.</div>
              <div class="no-data-text">Herhangi bir branş kaydı bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="30%" :close-on-click-modal="false">
        <div class="title">{{ form.Id == null ? "Branş Ekle" : "Branşı Güncelle" }}</div>
        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <div class="input-container">
            <label>Branş Tipi</label>
            <el-radio-group v-model="form.Type">
              <el-radio :label="true">Hastane</el-radio>
              <el-radio :label="false">Diğer</el-radio>
            </el-radio-group>
            <span class="error">{{ $errorMessage("Type", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Branş Adı</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Durum</label>
            <el-switch v-model="form.IsActive" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
            <span class="error">{{ $errorMessage("IsActive", ValidationErrors) }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addUpdateSpecialist()"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    
      form: {
        Id: null,
        Title: null,
        IsActive: true,
        Type:false,
      },
      allSpecialities: [],
      addUpdateDialog: false,
      onSave: false,
      ValidationErrors: [],
    };
  },

  async beforeMount() {
    await this.getData();
  },

  computed: {
    filter() {
      return this.$store.getters.getPolicyFilter;
    },

    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/Speciality/List", { SearchTerm: null, Page: 1, PageSize: 1000 });
      this.allSpecialities = res.data.Data.Items;
    },
    async addUpdateSpecialist() {
      this.ValidationErrors = [];
      // this.onSave = true;
      var res = await this.$client.post("/Speciality/AddOrUpdate", this.form);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    async deleteSpecialities(id) {
      var res = await this.$client.post("/Speciality/Delete", { ID: id });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    setItem(id) {
      this.ValidationErrors = [];

      if (id == null) {
        this.form.Id = null;
        this.form.Title = null;
        this.form.IsActive = true;
        this.form.Type = false;
      } else {
        var item = this.allSpecialities.find((x) => x.ID == id);
        this.form.Id = item.ID;
        this.form.Title = item.Title;
        this.form.IsActive = item.IsActive;
        this.form.Type = item.Type;
      }
      this.addUpdateDialog = true;
    },

    async setActivity(item) {
      var res = await this.$client.post("/Speciality/AddOrUpdate", item);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
  },
};
</script>

<style lang="less">
#policy-container {
  padding: 42px 30px;
  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;
    span {
      color: #8697a8;
    }
  }
  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }
      .delete {
        background: #eaedf0;
        i {
          background: #44566c;
        }
      }
      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #234A98;
        i {
          background: white;
        }
      }
    }
  }
  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;
        &::before {
          height: 0;
        }
      }
      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }
      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }
      table td {
        border: none;
      }
      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }
      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }
      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }
        th:first-child {
          padding-left: 15px;
        }

        .cell {
          font-weight: 500;
        }
      }
      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
        .el-table__row {
          &:hover {
            background-color: white !important;
          }
          td {
            height: 60px;
          }
          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 15px;
          }
          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
          background-color: white !important;
          border-radius: 10px !important;
        }
        .el-button {
          border: none;
        }
        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }
        td {
          .cell {
            i {
              height: 40px;
            }
          }
        }
      }
    }
    .el-dialog {
      width: 50%;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;
      // .el-dialog__body {
      //   padding-bottom: 0;
      //   margin-top: 18px;
      // }
      .el-icon-close:before {
        content: "";
      }
      .el-dialog__header {
        display: none;
      }
      padding: 0;
      position: relative;
      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;
        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }
        input {
          background: #f8fafb;
          border: none;
        }
        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }
        .el-select {
          width: 100%;
          border-color: #8697a8;
        }
        .text-edit {
          #toolbar {
            padding: 9px;
            border: none;
            background-color: #f8fafb !important;
            border-radius: 5px 5px 0px 0px;
            font-family: "Roboto" !important;
          }

          .ql-snow .ql-stroke {
            stroke: #8697a8;
          }
          .ql-active > .ql-snow .ql-stroke {
            stroke: #8697a8;
          }
          .ql-container {
            min-height: 100px;
            border: none !important;
            background-color: #f8fafb;
            resize: both;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #8697a8;
          }
        }
      }
      .dialog-footer {
        width: 100%;

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
          .btn-add {
            filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));

            width: 152px;
            background: #234A98;
            height: 40px;
            padding: 0;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: white;
            border: none;
            i {
              float: left;
            }
          }
          .btn-cancel {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            width: 110px;
            color: #234A98;
            background-color: #eae5f8;
            border: none;
          }
        }
      }
    }
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .el-radio-group {
      margin-bottom: 10px;
      margin-top: 10px;
      .el-radio {
        .el-radio__label {
          color: #234A98;
        }
        .is-checked {
          .el-radio__inner {
            border-color: #234A98;
            background-color: #234A98;
          }
        }
        .el-radio__input {
          .el-radio__inner {
            border-color: #234A98;
          }
        }
      }
    }
  }
  .el-switch__label.is-active {
    color: #234A98;
  }
}
</style>
